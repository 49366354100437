























































































































































































































































































































































































































































































































































































































































































































































































































.el-cascader__search-input {
  background: none !important;
}
.pztt {
  .el-form{
    .el-form-item {
      margin-bottom: 15px;
    }
    .shell {
      border-bottom: 1px solid #1b2e4b;
      margin-bottom: 15px;
    }
    .shell:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
    .el-checkbox {
      width: 20%;
      float: left;
    }
    .el-select,.el-input {
      width: 80%;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
}
