.el-cascader__search-input {
  background: none !important;
}
.pztt .el-form .el-form-item {
  margin-bottom: 15px;
}
.pztt .el-form .shell {
  border-bottom: 1px solid #1b2e4b;
  margin-bottom: 15px;
}
.pztt .el-form .shell:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.pztt .el-form .el-checkbox {
  width: 20%;
  float: left;
}
.pztt .el-form .el-select,
.pztt .el-form .el-input {
  width: 80%;
  float: left;
}
.pztt .el-form .el-button {
  float: right;
}
